
const setCameraFeedResumeStatus = (status = false) => {
  window.cameraFeedIsResuming = status;
}

const pauseQRScanMomentarily = () => {
  setCameraFeedResumeStatus(true);
}

const isQRCodeInactivated = () => {
  return window.qrCodeInactive
}

const setQRCodeScanningInactive = (status = true) => {  // used by the game
  window.qrCodeInactive = status;
}

/**
 * Used in qr-pipeline to delay QR reading, in order to avoid the canvas to read from the buffer
 * The canvas is always lagging a few frames behind from reality, so it can skip frames in the buffer if the workload is too much
 * @returns {bool}
 */
  const isCameraFeedResuming = () => {
  return window.cameraFeedIsResuming;
}

const pauseCamera = () => {
  XR8.pause();
}

const resumeCamera = (sceneEl) => {
  pauseQRScanMomentarily(); // clears the canvas buffer

  sceneEl.renderer.clear();
  window.XR8.resume();
}

const stopCamera = (sceneEl) => {
  pauseCamera();
  sceneEl.removeAttribute('xrextras-runtime-error');
  sceneEl.removeAttribute('xrweb');
  sceneEl.renderer.clear();
}

const restartCamera = (sceneEl) => {
  if (!sceneEl.hasAttribute('xrweb')) {
    sceneEl.setAttribute('xrweb', 'disableWorldTracking: true;');
    sceneEl.setAttribute('xrextras-runtime-error', '');
    window.dispatchEvent(new CustomEvent(
      'ec-image-targets-restart',
    ));  
  }
}

export { setCameraFeedResumeStatus, pauseQRScanMomentarily, isQRCodeInactivated, setQRCodeScanningInactive, isCameraFeedResuming, pauseCamera, resumeCamera, stopCamera, restartCamera }
