import * as Sentry from '@sentry/browser';

/**
 * Manually sends errors to Sentry
 * @param {Error} err
 */
const sendErrorReport = (err) => {
  console.error(err);
  Sentry.captureException(err);
}

export { sendErrorReport };
