import EcCustomElement from "../../ec-custom-element";
import "./ec-input-container";
import { localizeElements } from "../../../utils/language-helper";
import { stopCamera } from "../../../utils/camera-feed";

export default class EcGame1Strongbox extends EcCustomElement {
  __gameActivationListener
  __inputListener
  passwordSections = []
  passwordStr = ''
  inputPassword = {}
  allInputs = []

  html() {
    return `
      <section>
        <h1 id="game1-strongbox-title">[title]</h1>
        <div id="game1-strongbox-body" class="full-page__body">[body]</div>
        <div class="center-text bold full-page__input-container">
          <ec-input-container header="year"  inputs="4" idfield="1"></ec-input-container>
          <ec-input-container header="month" inputs="2" idfield="2"></ec-input-container>
          <ec-input-container header="day"   inputs="2" idfield="3"></ec-input-container>
        </div>
        <div id="game1-strongbox-error-text" hidden>[error]</div>
      </section>
    `
  }

  get allInputsFilled() {
    return Object.values(this.inputPassword).join('').length == this.passwordStr.length;
  }

  get inputPasswordStr() {
    return Object.values(this.inputPassword).join('');
  }

  get isValidPassword() {
    return this.passwordStr === this.inputPasswordStr
  }

  connectedCallback() {
    localizeElements(this, ['game1-strongbox-title', 'game1-strongbox-body', 'game1-strongbox-error-text']);

    this.__gameActivationListener = this.initStrongBox.bind(this);

    document.addEventListener(this.is, this.__gameActivationListener);
  }

  disconnectedCallback() {
    document.removeEventListener(this.is, this.__gameActivationListener);
    document.removeEventListener('keyup', this.__inputListener);
    this.__inputListener == undefined;
  }

  /**
   * Animates from hidden, stores references to all inputs, and stops the camera
   * 
   * @param {Object} detail password details
   */
  initStrongBox({detail}) {
    this.classList.remove('hide-animation--hidden');

    if (!this.__inputListener) {
      this.__inputListener = this.inputUpdated.bind(this);
      document.addEventListener('keyup', this.__inputListener);
    }

    this.passwordSections = this.setupGamePassword(detail.password);
    this.passwordStr = this.passwordSections.join('');
    this.allInputs = this.querySelectorAll('input');

    stopCamera(document.querySelector('a-scene'));
  }

  /**
   * Divides the password into different sections, so it's possible to mark faulty inputs for each section
   * 
   * @param {string} password 
   * @returns {array} three slots
   */
  setupGamePassword(password = '1085-05-21') {  // set in the manifest file under the 'ec_game1_strongbox' step
    return password.split('-');
  }

  /**
   * Events from inputs in 'ec-strongbox-input'
   *  Checks if all inputs have been filled
   *  Goes to the previous/next input if input is deleted/added
   *  Restricts the number of inputs to only one
   * 
   * @param {InputElement} target that sends the event
   * @param {string} key Which key that have been pressed
   */
  inputUpdated({target, key}) {
    if (target.dataset.name == 'strongbox') {
      this.hideErrorText();
      let value = target.value.trim();
      let inputId = target.dataset.inputid;

      if (target.checkValidity() && value.length == 1) {
        this.updateInputPassword(inputId, value);

        if (this.allInputsFilled) {
          target.blur();

          if (this.isValidPassword) {
            this.correctPassword();
          } else {
            this.wrongPassword();
          }
        } else {
          this.goToNextInput(target);
        }
      } else if (key == 'Enter') {
      } else if (key == 'Backspace' && !this.inputPassword[inputId]?.length) {
        this.updateInputPassword(inputId, value);
        this.goToPreviousInput(target);
      } else if (value.length > 1) {
        target.value = value[0];
      } else {
        value = '';
        this.updateInputPassword(inputId, value);
        target.value = value;
      }
    }
  }

  updateInputPassword(inputid, value) {
    this.inputPassword[inputid] = value;
  }

  /**
   * If hitting 'delete' in an empty input, go to the previous, if there is one
   * 
   * @param {InputElement} target 
   */
  goToPreviousInput(target) {
    let foundTarget = false;
    let currentEl;

    for (let i = this.allInputs.length - 1; i >= 0; i--) {
      currentEl = this.allInputs[i];

      if (foundTarget) {
        currentEl.select();
        return
      }

      foundTarget = foundTarget || currentEl === target;
    }
  }

  /**
   * Go to the next empty input, or start from the left until an empty one is hit
   * 
   * @param {InputElement} target 
   */
  goToNextInput(target) {
    let foundTarget = false;
    let currentEl;

    for (let i = 0; i < this.allInputs.length; i++) {
      currentEl = this.allInputs[i];

      if (foundTarget) {
        if (currentEl.value == '') {
          currentEl.select();
          return
        }
      }

      foundTarget = foundTarget || currentEl === target;
    }

    // if no empty inputs are found after current input, restart from the beginning
    if (!this.allInputs[0].value) {
      this.allInputs[0].focus();
    } else {
      this.goToNextInput(this.allInputs[0]);
    }
  }

  /**
   * When setting the correct password
   */
  lockAllInputs() {
    for (const input of this.allInputs) {
      input.readOnly = true;
    }
  }

  /**
   * Whenever a correct password is entered
   */
  correctPassword() {
    this.emit('ec-notification-banner', {action: 'hide'});

    this.lockAllInputs();
    this.hideComponent();
    this.emit('game1_ec_game1_strongbox');
  }

  hideComponent() {
    this.classList.add('hide-animation--hidden');
  }

  wrongPassword() {
    // this.allInputsFilled set faulty values to '';
    this.invalidateInputs();
    this.hideErrorText(false);
    this.emit('ec-clue-icon', {action: 'primary-button'});
  }

  hideErrorText(hidden = true) {
    this.querySelector('#game1-strongbox-error-text').hidden = hidden;
  }

  /**
   * Loop through, and compare, each of the three password section, and remove data from the section inputs if the whole section is wrong
   */
  invalidateInputs() {
    let currentSection = 0;
    let inputPasswordStr = this.inputPasswordStr;

    for (const passwordSection of this.passwordSections) {
      if (inputPasswordStr.slice(currentSection, currentSection + passwordSection.length) != passwordSection) {
        for (let i = 0; i < passwordSection.length; i++) {
          this.deleteValues(this.allInputs[i+currentSection]);
        }
      }

      currentSection += passwordSection.length;
    }
  }

  /**
   * Clear input, add class, and update stored password.
   */
  deleteValues(element) {
    element.classList.add('invalid');
    element.value = '';
    this.updateInputPassword(element.dataset.inputid, '');
  }
}

customElements.define('ec-game1-strongbox', EcGame1Strongbox);
