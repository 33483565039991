const creditString = `
     _                _                      _   _            
  __| | _____   _____| | ___  _ __   ___  __| | | |__  _   _  
 / _\` |/ _ \\ \\ / / _ \\ |/ _ \\| '_ \\ / _ \\/ _\` | | '_ \\| | | | 
| (_| |  __/\\ V /  __/ | (_) | |_) |  __/ (_| | | |_) | |_| | 
 \\__,_|\\___| \\_/ \\___|_|\\___/| .__/ \\___|\\__,_| |_.__/ \\__, | 
                             |_|                       |___/  
                     _       _   _                            
  ___  ___ ___  ___ | |_   _| |_(_) ___  _ __  ___   ___  ___ 
 / _ \\/ __/ __|/ _ \\| | | | | __| |/ _ \\| '_ \\/ __| / __|/ _ \\
|  __/ (__\\__ \\ (_) | | |_| | |_| | (_) | | | \\__ \\_\\__ \\  __/
 \\___|\\___|___/\\___/|_|\\__,_|\\__|_|\\___/|_| |_|___(_)___/\\___|
                                                              
`;

export default creditString;
