/** 
* Component for a-scene
* Currently adding QR Code reading to the pipeline, but should be used for any kind of pipeline addition
*/

import {qrprocessPipelineModule, qrdisplayPipelineModule} from '../qr/qr-pipelines'

const pipelineAdditions = {
  init() {
    const load = () => {
      window.removeEventListener('xrloaded', load);
      
      XR8.addCameraPipelineModules([
        XR8.CameraPixelArray.pipelineModule({luminance: false, maxDimension: 640}),
        qrprocessPipelineModule(),
        qrdisplayPipelineModule(),
      ])
    }
    
    window.XR8 ? load() : window.addEventListener('xrloaded', load);
  }
}

export {pipelineAdditions}
