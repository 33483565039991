import EcCustomElement from "../../ec-custom-element";

export default class EcClueIcon extends EcCustomElement {
  galleryInformation = {};

  html() {
    return `
      <button class="ec-fab-button__button">
        <svg viewBox="0 0 40 40" xmlns:xlink="http://www.w3.org/1999/xlink">
          <use xlink:href="bundle.svg#icon-clues"/>
        </svg>
      </button>
    `
  }

  constructor() {
    super();

    window.addEventListener(this.is, this.updateInformation.bind(this));
    this.addEventListener('click', this.showGallery.bind(this));
  }

  /**
   * Handles 'ec-clue-icon' event, where it may store information about the gallery
   * @param {object} detail from event
   */
  updateInformation({detail}) {
    this.classList.remove('hide-animation--hidden');

    if (detail.action == 'gallery-update') {
      this.galleryInformation = detail;
    } else if (detail.action == 'hide') {
      this.classList.add('hide-animation--hidden');
    } else if (detail.action == "primary-button") {
      this.firstElementChild.classList.add("primary");
    }
  }

  showGallery() {
    this.firstElementChild.classList.remove("primary"); // removes yellow background
    this.emit('ec-show-gallery', this.galleryInformation);
  }
}

customElements.define('ec-clue-icon', EcClueIcon);
