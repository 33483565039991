import EcCustomElement from "../../ec-custom-element";
import '../../../../scss/elements/ec-strongbox-input.scss';

export default class EcStrongboxInput extends EcCustomElement {
  __clickListener
  __inputListener

  html() {
    return `
      <section class="strongbox-section center-text bold">
        <div class="strongbox-section__placeholder"><div>9</div></div>
        <div>
          <input
            type="number"
            placeholder="?"
            class="center-text bold"
            data-name="strongbox"
            inputmode="numeric"
            pattern="\\d*"
        /></div>
        <div class="strongbox-section__placeholder"><div>1</div></div>
      </section>
    `
  }

  get inputid() {
    return this.getAttribute('inputid') || 'notset';
  }

  get randomNumber() {
    return Math.floor(Math.random() * 9);
  }

  constructor() {
    super();

    this.__clickListener = this.selectInput.bind(this);
    this.__inputListener = this.animatePlaceholderNumbers.bind(this);

    this.addEventListener('click', this.__clickListener);
    this.addEventListener('input', this.__inputListener);
  }

  connectedCallback() {
    this.querySelector('input').setAttribute('data-inputid', this.inputid);
  }

  disconnectedCallback() {
    this.removeEventListener('click', this.__clickListener);
    this.removeEventListener('input', this.__inputListener);
  }

  selectInput() {
    this.querySelector('input').select();
  }

  /**
   * Animates the top and bottom text elements whenever the user do changes in the input
   * Used 'setInterval' on purpose to get a stylized janky animation
   * 
   * @param {Element} target the input
   */
  animatePlaceholderNumbers({target}) {
    target.classList.remove('invalid');
    
    if (target.checkValidity()) {
      // subtract one, but set '9' if '0'.
      const topFinishedValue    = (target.value == '') ? 9 : (target.value == 0) ? 9 : target.value - 1;
      // add one, but set '0' if higher than '9'.
      const bottomFinishedValue = (target.value == '') ? 1 : (target.value/1 + 1) % 10;

      const topEl = this.querySelector('section > div:first-child > div');
      const bottomEl = this.querySelector('section > div:last-child > div');
      const numberOfChanges = 10;
      let iterations = 0;

      const intervalId = setInterval(() => {
        if (iterations < numberOfChanges) {
          topEl.textContent = this.randomNumber;
          bottomEl.textContent = this.randomNumber;  
        } else {
          topEl.textContent = topFinishedValue;
          bottomEl.textContent = bottomFinishedValue;

          clearInterval(intervalId);
        }

        iterations++;
      }, 50)
    }
  }
}

customElements.define('ec-strongbox-input', EcStrongboxInput);
