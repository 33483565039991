import EcCustomElement from '../ec-custom-element';
import './../../../scss/elements/ec-gallery.scss';
import PhotoSwipe from 'photoswipe/lightbox';
import DynamicCaption from 'photoswipe-dynamic-caption-plugin';
import 'photoswipe/style.css';
import 'photoswipe-dynamic-caption-plugin/photoswipe-dynamic-caption-plugin.css';
import { removePointFromSearchParams } from '../../utils/search-params';
import { pauseCamera, resumeCamera } from '../../utils/camera-feed';
import { getCurrentLanguage } from "../../utils/language-helper";
import { sendErrorReport } from '../../utils/sentry-error';
import { localize } from '../../utils/language-helper';

export default class EcGallery extends EcCustomElement {
  id = ''
  isPartOfGame = false

  html() {
    return `
      <div class="gallery">
        <h3 class="gallery__header">[title]</h3>
        <div class="gallery__container pswp-gallery pswp-gallery--single-column" id="gallery"></div>
      </div>
    `
  }

  /**
   * Initialize component and listener for ec-show-gallery event.
   *
   * @return void
   */
  constructor() {
    super();

    window.addEventListener('ec-show-gallery', (event) => this.initGallery(event));
    window.addEventListener('ec-gallery-close', () => this.closeDetailView());

    this.galleryEl = document.getElementById('gallery');
  }

  /**
   * Start image gallery and pauses the AR session.
   *
   * @param detail - Event with gallery data.
   * @return void
   */
  initGallery({detail}) {
    if (detail.action == 'close') {
      this.exitGallery();
      return
    }

    this.isPartOfGame = detail.isPartOfGame;

    this.setupGallery();

    document.querySelector('.gallery__header').textContent = localize(detail.id);

    this.id = detail.id;

    this.loadImages(detail.id, detail.images);

    this.showCloseButton();
    this.toggleVisibility(true);

    pauseCamera();
  }

  /**
   * Inialize Photoswipe with options
   *
   * @return void
   */
  setupGallery() {
    this.gallery = new PhotoSwipe({
      gallery: '#gallery',
      children: 'a',
      bgOpacity: 1,
      close: false,
      zoom: false,
      preload: [2, 2],
      closeOnVerticalDrag: false, // don't close gallery by swiping down
      pswpModule: () => import('photoswipe'),
    });

    // Add another 'layer' in ec-close-button to close
    this.gallery.on('openingAnimationStart', () => {
      this.emit('ec-close-button', {
        name: 'ec-gallery-close',
        action: 'show',
        data: {}
      });
    });

    const captionPlugin = new DynamicCaption(this.gallery, {
      type: 'below',
    });

    this.gallery.init();
  }

  /**
   * Creating elements for the gallery images
   *
   * @param {array} images - list of images from manifest
   * @return void
   */
  async loadImages(galleryName, images) {
    const base_url = `${process.env.SCALEWAY_ASSETS_URL}galleries/${galleryName}/`;
    const thumbnail_url = `https://dunkers.twic.pics/assets/galleries/${galleryName}/`;
    const placeholder_url = `${process.env.SCALEWAY_ASSETS_URL}img/thumbs/gallery.png`;

    const currentLang = getCurrentLanguage();

    for (const image of images) {
      if (image.visible === false) { continue } // for the game

      // Container
      const anchor = document.createElement('a');
      anchor.classList = 'gallery__image-holder loading';

      // Link to fullsized image that is loaded on the detail view
      anchor.setAttribute('href', `${base_url}${image.filename}.${image.filetype}`);

      // Image
      const img = document.createElement('img');
      img.src = placeholder_url;
      img.alt = '';
      img.classList = 'gallery__image';

      anchor.appendChild(img);

      this.downloadThumbnail(anchor, img, image, `${thumbnail_url}${image.filename}.${image.filetype}?twic=v1/contain=-x300`);

      // Captions
      if (image.caption) {
        const caption = document.createElement('span');
        caption.classList = 'pswp-caption-content';
        caption.innerHTML = image.caption[currentLang] || '';
        anchor.appendChild(caption);
      }

      this.galleryEl.appendChild( anchor );
    };
  }

  /**
   * Replaces the placeholder image when loaded, or removes the anchor if failing to load
   *
   * @param {Element} anchor image container
   * @param {Element} img image with placeholder
   * @param {Object} image data from manifest
   * @param {string} thumbnailUrl
   */
  downloadThumbnail(anchor, img, image, thumbnailUrl) {
      new Promise(() => {
        const placeholder = new Image();

        placeholder.onload = () => {
          img.src = thumbnailUrl;
          anchor.setAttribute('data-pswp-width', image.width);
          anchor.setAttribute('data-pswp-height', image.height);
          anchor.classList.remove('loading');

          placeholder.onload = null;
        }

        placeholder.onerror = () => {
          anchor.remove();
          sendErrorReport(new Error(`Gallery: error loading dimensions for ${image.filename}.${image.filetype}`));

          placeholder.onerror = null;
        };

        placeholder.src = thumbnailUrl;
      });
  }

  /**
   * Closes the detail view (named 'gallery' in PhotoSwipe documentation)
   *
   * @return void
   */
  closeDetailView() {
    this.gallery?.pswp?.close();
  }

  /**
   * Trigger animation for gallery when opening/closing
   *
   * @param {array} images - list of images from manifest
   * @return void
   */
  toggleVisibility(show) {
    this.classList.toggle('hide-animation--hidden', !show);
  }

  /**
   * Exit the gallery and resume the AR session.
   * Also remove url params so gallery can be activated again.
   *
   * @return void
   */
  exitGallery() {
    if (this.id) {
      this.toggleVisibility(false);

      setTimeout(() => {
        this.gallery.destroy();
        this.galleryEl.innerHTML = '';
      }, 350);


      if (this.isPartOfGame) {
        this.notifyGameAboutClosing(this.id);
      } else {
        // Removes point in search params so that the gallery can be triggered again
        removePointFromSearchParams(this.id, 'always-remove');
      }

      resumeCamera(document.querySelector('a-scene'));

      this.id = '';
    }
  }

  /**
   * The game listens for the gallery name
   */
  notifyGameAboutClosing(galleryName) {
    window.dispatchEvent(new Event(galleryName));
  }

  /**
   * Activates the show button
   */
  showCloseButton() {
    this.emit('ec-close-button', {
      name: 'ec-show-gallery',
      action: 'show',
      layer: 1,
      data: {
        action: 'close'
      }
    });
  }

  /**
   * Component cleanup
   *
   * @return void
   */
  remove() {
    window.removeEventListener('ec-show-gallery', (event) => this.initGallery(event));
    window.removeEventListener('ec-gallery-close', () => this.closeDetailView());
  }
}

customElements.define('ec-gallery', EcGallery);
