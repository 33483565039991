import { sendErrorReport } from '../../utils/sentry-error';

/**
 * Downloads and stores manifest files in memory
 * this.manifest contains all possible rooms with their ids
 */

export default class ManifestDownloader {
  manifest = {};
  failed = new Set();   // if any manifest failed to load
  RETRY_TIME = 20000;

  // If it's not in the list, the manifest will download when the room code is triggered
  roomsToPreload = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

  exists(roomId) { return this.getManifest(roomId) !== undefined}

  getManifest(roomId) { return this.manifest[roomId]; }

  updateManifest(roomId, data) { this.manifest[roomId] = data; }

  async get(roomId = 1, callbackFn) {
    let room = {};

    if (this.exists(roomId)) {
      room = this.getManifest(roomId);
    } else {
      room = await this.download(roomId);
    }

    callbackFn({...room});  // Removing reference, so elements can modify it.
  }

  async download(roomId) {
    try {
      let room = await fetch(`/assets/manifests/${roomId}.json`);
      room = await room.json();

      room.imageTargets = this.addImageTargetIds(room.points);

      this.updateManifest(roomId, room);

      this.failed.delete(roomId);

      return room;
    } catch (error) {
      this.failed.add(roomId);

      sendErrorReport(new Error(`Couldn't download manifest for room ${roomId}`));
      return {}
    }
  }

  /**
   * Fetches all manifests, and retries if anyone fails
   */
  async getAll() {
    await this.downloadAll(this.roomsToPreload);

    if (this.failed.size) {
      setTimeout(() => {
        this.downloadAll(Array.from(this.failed));
      }, this.RETRY_TIME);
    }
  }

  async downloadAll() {
    let allRoomsPromise = [];

    for (const roomId of this.roomsToPreload) {
      if (!this.exists(roomId)) {
        allRoomsPromise.push(this.download(roomId));
      }
    }

    return Promise.all(allRoomsPromise);
  }

  /**
   * Creates an array of image targets that the app should scan for
   * 
   * @param {object} points 
   * @returns {[string]Array} list of image targets
   */
  addImageTargetIds(points) {
    let imageTargetIds = [], point = {};

    for (const pointId in points) {
      point = points[pointId];

      point.id = pointId;
      point.name = pointId;

      if (point.image_target) {
        imageTargetIds.push(pointId);
      }
    }

    return imageTargetIds;
  }
}
