/**
*   
    // GENERAL USAGE
    window.dispatchEvent(new CustomEvent('ec-notification-banner', {detail: {
        action: 'show',  // alt. 'hide'
        title: 'keyword_title',
        body: 'keyword_body',
        animation: false,
        clickAction: () => {},
      }
    }));

    // FOR COMPONENT
    this.el.emit('ec-notification-banner', {
      action: 'show',
      title: 'keyword_title',
      body: 'keyword_body',
      animation: false,
      clickAction: () => {},
    });

    NOTE
    'title' and 'body' should be keys that are added to the dictionary in language-helper.
    You need to add custom 'clickAction' manually at the bottom of this file, if you want something special to occur when the notification closes.
*/

import EcCustomElement from '../ec-custom-element';
import '../../../scss/elements/ec-notification-banner.scss';
import { localize } from '../../utils/language-helper';

export default class EcNotificationBanner extends EcCustomElement {
  clickAction

  html() {
    return `
      <h3 id="banner-title">[title]</h3>
      <div id="banner-body">[body]</div>
    `
  }

  constructor() {
    super();
    window.addEventListener('ec-notification-banner', this.handleEvent.bind(this));
    this.addEventListener('click', this.handleClick.bind(this));
  }

  /**
   * Animates a switchout of a banner, if the banner is visible
   * Otherwise, animate the banner from a hidden state
   * @param {object} detail from event
   */
  handleEvent({detail}) {
    if (detail.action == 'show') {
      if (this.classList.contains('hide-animation--hidden')) {
        this.updateNotification(detail);
        this.showNotification();
      } else {
        if (detail.animation !== false) {
          this.hideNotification();
          setTimeout(() => { this.handleEvent({detail}) }, 150);
        } else {
          this.updateNotification(detail);
        }
      }
    } else if (detail.action == 'hide' && !this.classList.contains('hide-animation--hidden')) {
      this.hideNotification();
    }
  }

  /**
   * Sets the text and eventual action for the banner
   * 
   * @param {string} title a key that should be translated
   * @param {string} body a key that should be translated
   * @param {string?} clickAction a string that should be handled in handleClick() 
   */
  updateNotification({title, body, clickAction}) {
    this.querySelector('#banner-title').textContent = localize(title);
    this.querySelector('#banner-body').textContent = localize(body);

    this.clickAction = clickAction;
  }

  showNotification() {
    this.classList.remove('hide-animation--hidden');
  }

  hideNotification() {
    this.classList.add('hide-animation--hidden');
  }

  handleClick() {
    this.additionalAction(this.clickAction);

    this.hideNotification();
  }

  additionalAction(clickAction) {
    if (clickAction && typeof clickAction == 'function') {
      clickAction();
      clickAction = null;
    }
  }
}

customElements.define('ec-notification-banner', EcNotificationBanner);
