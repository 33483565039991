import EcCustomElement from '../ec-custom-element';
import './../../../scss/elements/ec-audio-player.scss';
import './../../../scss/elements/ec-fab-button.scss';

import { getCurrentLanguage, setCurrentLanguage } from '../../utils/language-helper';
import { removePointFromSearchParams } from '../../utils/search-params';
import { analyticsSendTrigger } from '../../utils/matomo-analytics';
import { sendErrorReport } from '../../utils/sentry-error';

export default class EcAudioPlayer extends EcCustomElement {
  id = '';
  isPartOfGame = false;

  html() {
    return `
      <div class="ec-fab-button ec-fab-button--left">
        <button id="audio-lang" class="audio-controller__lang ec-fab-button__button">
          <svg viewBox="0 0 31 28" xmlns:xlink="http://www.w3.org/1999/xlink">
            <use id="current-flag" xlink:href="bundle.svg#icon-flag-sv"/>
          </svg>
        </button>
      </div>

      <div class="audio-controller__selection ec-fab-button ec-fab-button--left hide-animation hide-animation--hidden">
        <button class="audio-controller__lang ec-fab-button__button">
          <svg viewBox="0 0 31 28" xmlns:xlink="http://www.w3.org/1999/xlink">
            <use id="selected-flag" xlink:href="bundle.svg#icon-flag-en"/>
          </svg>
        </button>
      </div>

      <div class="audio-controller__actions ec-fab-button ec-fab-button--large ec-fab-button--middle">
        <button class="audio-controller__play-pause ec-fab-button__button">
          <svg class="audio-controller__pause" viewBox="0 0 24 24" xmlns:xlink="http://www.w3.org/1999/xlink">
            <use id="play-pause" xlink:href="bundle.svg#icon-player-pause"/>
          </svg>
        </button>
      </div>
    `
  }

  get reverseLanguage() {
    return (this.currentLanguage == 'en') ? 'sv' : 'en';
  }

  /**
   * Init audio controller.
   *
   * @return void
   *
   */
  connectedCallback() {
    this.currentSource = '';

    this.player = document.querySelector('#audio-player');

    this.playPauseButton = this.querySelector('.audio-controller__play-pause');
    this.languageButton = this.querySelector('#audio-lang');
    this.selectableLanguageContainer = this.querySelector('.audio-controller__selection')
    this.selectableLanguageButton = this.selectableLanguageContainer.querySelector('button');

    this.isPlaying = false;

    this.currentLanguage = getCurrentLanguage();

    this.playPauseButton.addEventListener('click', this.playPause.bind(this));
    this.languageButton.addEventListener('click', this.toggleLangMenu.bind(this));
    this.selectableLanguageButton.addEventListener('click', this.changeLanguage.bind(this));
    this.player.addEventListener('error', this.handleError.bind(this));

    window.addEventListener('ec-play-audio', this.playerInputs.bind(this));

    this.player.addEventListener('loadeddata', () => {
      if (!this.isPlaying) {
        this.player.pause();
        this.player.currentTime = 0;
      }
    });
  }

  /**
   * Component cleanup.
   *
   * @return void
   */
  disconnectedCallback() {
    this.playPauseButton.removeEventListener('click', this.playPause, false);
    this.languageButton.removeEventListener('click', this.toggleLangMenu, false);

    window.removeEventListener('ec-play-audio', this.playerInputs, false);
  }

  /**
   * Handles player events.
   *
   * @param {object} detail - Player event
   * @return void
   */
  playerInputs({detail}) {
    switch (detail.action) {
      case 'start':
        if (this.id === detail.name) return;

        this.id = detail.id;
        this.isPlaying = false;
        this.isPartOfGame = detail.is_part_of_game;
        this.switchLangIcons(this.currentLanguage);
        this.togglePlaybackIcons(true);
        this.toggleCloseButton();
        this.swapSource(detail.id, this.currentLanguage);
        this.startPlayer(!!detail?.image_target && detail?.hide_controller !== 0);
        break;
      case 'close':
        this.stopPlayer(this.currentSource);
        break;
      default:
        console.log(this.is, 'Unknown action: ', detail.action);
        break;
    }
  }

  /**
   * Handles player play/pause.
   *
   * @return void
   */
  playPause() {
    if (!this.isPlaying) {
      this.player.play();
    } else {
      this.player.pause();
    }

    this.isPlaying = !this.isPlaying;
    this.togglePlaybackIcons();
  }

  /**
   * Make the player visible and starts playing.
   *
   * @param {boolean} hideControls
   * @return void
   */
  startPlayer(hideControls) {
    this.isPlaying = true;
    if (!hideControls) this.classList.remove('hide-animation--hidden');
    this.player.play()
    .catch(error => {
      if (error.name === 'NotAllowedError') {
        this.isPlaying = false;
        this.showNotificationForIos();
        this.togglePlaybackIcons();
      } else {
        sendErrorReport(new Error('Failed to load audio ' + this.currentSource));
      }
    });
  }

  /**
   * A fallback for the first time an audio is loaded on the site
   * If the user taps once on the player, it will autoplay the rest of the clips
   */
  showNotificationForIos() {
    window.dispatchEvent(new CustomEvent('ec-notification-banner', {detail: {
        action: 'show',
        title: 'ios_audio_loaded_title',
        body: 'ios_audio_loaded_body',
        animation: false,
        clickAction: () => {
          if (!this.isPlaying) {
            this.playPause();
          }
        },
      }
    }));
  }

  hideNotification() {
    window.dispatchEvent(new CustomEvent('ec-notification-banner', {detail: {
      action: 'hide',
    }}));
  }

  /**
   * Hides and stops player.
   *
   * @return void
   */
  stopPlayer(pointId) {
    this.id = '';
    this.isPlaying = false;
    this.sendProgressToAnalytics();
    this.classList.add('hide-animation--hidden');
    this.player.pause();
    this.player.currentTime = 0;
    this.hideNotification();
    removePointFromSearchParams(pointId, !this.isPartOfGame);
    this.selectableLanguageContainer.classList.add('hide-animation--hidden');

    this.emit(pointId); // for the game
  }

  /**
   * Sends every 10th percentage to Mamoto
   *
   * @return void
   */
  sendProgressToAnalytics() {
    if (this.player) {
      const wholeTenPercentage = Math.round(this.player.currentTime / this.player.duration * 10)*10;
      analyticsSendTrigger('audioProgress', this.currentSource, wholeTenPercentage);
    }
  }

  /**
  * Swap the image source.
  *
  * @param {string} source - The new source filename
  * @return void
  */
  swapSource(source, lang) {
    this.currentSource = source;
    this.player.setAttribute('src', `${process.env.SCALEWAY_ASSETS_URL}audio/${lang}/${this.currentSource}.mp3`);
    this.player.load();
  }

  /**
   * Toggles play pause svg.
   *
   * @return void
   */
  togglePlaybackIcons(toggleState) {
    let showPause = (toggleState !== undefined) ? toggleState : this.isPlaying;
    this.querySelector('#play-pause').setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', showPause ? 'bundle.svg#icon-player-pause' : 'bundle.svg#icon-player-play');
  }

  /**
   * Change language. Swapping the two langauages are hard coded through 'reverseLanguage'
   *
   * @return void
   */
  changeLanguage(detail) {
    const newLanguage = this.reverseLanguage;

    this.swapSource(this.currentSource, newLanguage);

    if (detail !== 'changeLanguageForThisClipOnly') {
      this.currentLanguage = newLanguage;
      setCurrentLanguage(newLanguage);
    }

    this.toggleLangMenu();
    this.switchLangIcons(newLanguage);

    // Restart the audio if it's playing with new language
    if (this.isPlaying) {
      this.player.pause();
      this.player.play();
    }
  }

  /**
   * Handles errors from audio player.
   *
   * @param event - The event
   * @return void
   */
  handleError(event) {
    const error = event.target.error;
    switch (error.code) {
      case error.MEDIA_ERR_SRC_NOT_SUPPORTED:
        if (this.currentLanguage !== 'sv') {
          this.changeLanguage('changeLanguageForThisClipOnly');
          this.displayErrorMessageToUser();
        }
        break;
      default:
        sendErrorReport(new Error('Media player error with code: ' + error.code));
        break;
    }
  }

  displayErrorMessageToUser() {
    window.dispatchEvent(new CustomEvent('ec-notification-banner', {detail: {
        action: 'show',
        title: "I'm sorry!",
        body: "The audio isn't available in English.",
      }
    }));

    setTimeout(() => {
      window.dispatchEvent(new CustomEvent('ec-notification-banner', {detail: {
          action: 'hide',
        }
      }));
    }, 5000);
  }

  /**
  * Switches icons between the languages
  *
  * @param {string} code - The language code
  * return void
  */
  switchLangIcons(code) {
    this.languageButton.querySelector('#current-flag').setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `bundle.svg#icon-flag-${code}`);
    this.selectableLanguageButton.querySelector('#selected-flag').setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `bundle.svg#icon-flag-${this.reverseLanguage}`);
  }

  /**
   * Toggle the language menu
   *
   * @return void
   */
  toggleLangMenu() {
    this.selectableLanguageContainer.classList.toggle('hide-animation--hidden');
  }

  /**
   * Toggles the close button
   *
   * @param action
   * @return void
   */
  toggleCloseButton(action = 'show') {
    if (action == 'close' || this.classList.contains('hide-animation--hidden')) {
      this.emit('ec-close-button', {
        name: 'ec-play-audio',
        action: action,
        data: {
          action: 'close',
          id: 'ec-close-button'
        }
      });
    }
  }
}

customElements.define('ec-audio-player', EcAudioPlayer);
