import EcCustomElement from "../../ec-custom-element";
import '../../../../scss/elements/full-page.scss';
import "./ec-game1-strongbox.js";
import "./ec-game1-winner-page.js";

/**
 * Container for custom made elements for 'game1'
 * TODO: Not adding the elements to the DOM until the game is actually activated
 */
export default class EcGame1NarBlevHelsingborgEnStad extends EcCustomElement {
  __listener

  html() {
    return `
      <ec-game1-strongbox class="full-page hide-animation hide-animation--hidden"></ec-game1-strongbox>
      <ec-game1-winner-page class="full-page hide-animation hide-animation--hidden"></ec-game1-winner-page>
    `
  }

  constructor() {
    super();

    this.__listener = this.hideGameComponents.bind(this);
    window.addEventListener('game1_nar_blev_helsingborg_en_stad_component', this.__listener);
  }

  hideGameComponents() {
    this.hideElement('ec-game1-strongbox');
    this.hideElement('ec-game1-winner-page');
  }

  hideElement(elName) {
    const el = this.querySelector(elName);
    el.classList.add('hide-animation--hidden');
  }

  disconnectedCallback() {
    window.removeEventListener('game1_nar_blev_helsingborg_en_stad_component', this.__listener);
  }
}

customElements.define('ec-game1-nar-blev-helsingborg-en-stad', EcGame1NarBlevHelsingborgEnStad);
