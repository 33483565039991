/**
 * Container for all ec-strong-box-inputs
 */

import EcCustomElement from "../../ec-custom-element";
import "./ec-strongbox-input";
import '../../../../scss/elements/ec-input-container.scss';
import { localize } from "../../../utils/language-helper";

export default class EcInputContainer extends EcCustomElement {

  html() {
    return `
      <div id="container-header" class="center-text"></div>
      <template id="strongbox">
        <ec-strongbox-input></ec-strongbox-input>
      </template>
    `
  }

  get idfield() {
    return this.getAttribute('idfield') || '';
  }

  get header() {
    return this.getAttribute('header') || '';
  }

  get inputs() {
    return Number(this.getAttribute('inputs')) || 0;
  }

  connectedCallback() {
    const headerEl = this.querySelector('#container-header')

    if (headerEl) {
      headerEl.textContent = localize(this.header);
      this.insertBefore(this.createInputs(this.inputs), headerEl);
    }
  }

  /**
   * Dynamically creates the inputs from the template
   * 
   * @param {integer} numberOfInputs 
   */
  createInputs(numberOfInputs = 0) {
    let template = this.querySelector('#strongbox');
    const sectionEl = document.createElement('section');
    let inputEl;

    if (template) {
      for (let id = 0; id < numberOfInputs; id++) {
        inputEl = template.content.cloneNode(true);
        inputEl.firstElementChild.setAttribute('inputid', this.idfield + id);
        sectionEl.appendChild(inputEl);
      }
    }

    return sectionEl
  }
}

customElements.define('ec-input-container', EcInputContainer);
