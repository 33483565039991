import { localize } from '../../utils/language-helper';
import EcCustomElement from '../ec-custom-element';
import '../../../scss/elements/ec-dialog.scss';

/**
 * Displays a modal.
 * Instead of text, use keys as strings, as 'text' is automatically localized.
 * You can send a callback if you want any button to do anything special.
 * It's also possible to send a text string, which will send that string back as an event.
 * 
    // GENERAL USAGE
    window.dispatchEvent(new CustomEvent('ec-dialog', {detail: {
      headline: 'key_headline',  // optional
      body: 'key_body',
      primaryButton: {
        action: () => location.reload(),
        text: 'key_continue'
      },
      secondaryButton: {  // optional
        text: 'key_cancel'
      }
    }}));

    // FOR COMPONENT
    this.el.emit('ec-dialog', {
      headline: 'key_headline',  // optional
      body: 'key_body',
      primaryButton: {
        action: () => location.reload(),
        text: 'key_continue'
      },
      secondaryButton: {  // optional
        text: 'key_cancel'
      }
    });
 */

export default class EcDialog extends EcCustomElement {
  html() {
    return `
      <dialog class="dialog dialog--displace">
        <h2 id="dialog-title" class="dialog__title">[title]</h2>
        <div id="dialog-body" class="dialog__body">[body]</div>

        <div class="button-container dialog__buttons">
          <button id="dialog-secondary" class="dialog__secondary-button" hidden></button>
          <button id="dialog-primary" class="primary dialog__primary-button"></button>
        </div>
      </dialog>
    `
  }

  __primaryButtonAction = null
  __secondaryButtonAction = null

  constructor() {
    super();

    this.dialogEl = this.querySelector('dialog');
    this.bodyEl = document.querySelector('#dialog-body');
    this.titleEl = document.querySelector('#dialog-title');
    this.primaryButton = document.querySelector('#dialog-primary');
    this.secondaryButton = document.querySelector('#dialog-secondary');

    this.primaryButton.addEventListener('click', this.primaryButtonAction.bind(this));
    this.secondaryButton.addEventListener('click', this.secondaryButtonAction.bind(this));
    window.addEventListener('ec-dialog', this.showDialog.bind(this));
  }

  showDialog({detail}) {
    this.setContent(detail);
    this.dialogEl.showModal();
  }

  /**
   * Adds content to the the dialog
   * Body text and primary button are obligatory
   * Headline and secondary button are optional
   * 
   * @param {event} detail - see example code at the top
   */
  setContent(detail) {
    this.bodyEl.textContent = localize(detail.body);
    this.primaryButton.textContent = localize(detail.primaryButton.text);
    this.__primaryButtonAction = detail.primaryButton.action;

    this.titleEl.hidden = detail.headline === undefined;
    this.secondaryButton.hidden = detail.secondaryButton === undefined;

    if (detail.headline) {
      this.titleEl.textContent = localize(detail.headline);
    }

    if (detail.secondaryButton) {
      this.secondaryButton.textContent = localize(detail.secondaryButton.text);
      this.__secondaryButtonAction = detail.secondaryButton.action;  
    }
  }

  /**
   * Whatever element that displayed the banner can send in a callback to that element
   * Otherwise, if a string, it sends an event whenever the button is clicked
   * 
   * @param {function/string} action 
   */
  handleAction(action) {
    if (typeof this.action == 'function') {
      action();
    } else if (typeof action == 'string') {
      window.dispatchEvent(new Event(action));
    }
  }

  primaryButtonAction() {
    this.handleAction(this.__primaryButtonAction);
    this.closeDialog();
  }

  secondaryButtonAction() {
    this.handleAction(this.__secondaryButtonAction);
    this.closeDialog();
  }

  closeDialog() {
    this.__primaryButtonAction = null;
    this.__secondaryButtonAction = null;

    this.dialogEl.close();
  }
}

customElements.define('ec-dialog', EcDialog);
