/**
 * Changes the loading page and translates the error screens
 */
import { localize, getCurrentLanguage } from "../../utils/language-helper";

const loadingLocalization = {
  init() {
    const load = () => {
      // These are created by 8th Wall, and are removed when the camera successfully starts
      const loadingScreen = document.getElementById('loadBackground');
      const cameraDeniedSafari = document.getElementById('cameraPermissionsErrorApple');
      const cameraDeniedAndroid = document.getElementById('cameraPermissionsErrorAndroid');
      const motionSensorDenied = document.getElementById('userPromptError');
      const motionSensorHardDenied = document.getElementById('motionPermissionsErrorApple');

      let inDom = false;

      const changeText = (element, str) => {
        if (element) { element.textContent = localize(str) }
      }

      const changeHTML = (element, htmlStr) => {
        if (element) { element.innerHTML = htmlStr }
      }

      // Adding elements to loading screen
      if (loadBackground) {
        const container = document.createElement('section');
        const dunkersLogo = document.createElement('img');
        const h1 = document.createElement('h1');
        const tagline = document.createElement('p');

        container.classList = 'welcome-screen normal center-text';
        dunkersLogo.classList = 'welcome-screen__logotype';
        h1.classList = 'welcome-screen__headline center-text';
        tagline.classList = 'center-text';

        dunkersLogo.src = `${process.env.SCALEWAY_ASSETS_URL}img/dunkers-logo.png`;
        changeHTML(h1, `${localize('welcome_to')} <br/> <span class="bold">Nu och då</span>`);
        changeText(tagline, localize('tagline'));

        container.appendChild(dunkersLogo);
        container.appendChild(h1);
        container.appendChild(tagline);

        loadingScreen.appendChild(container);
      }

      // Translating and styling the gyro access request popup for Safari
      const observer = new MutationObserver(() => {
        const promptBox = document.querySelector('.prompt-box-8w');

        if (promptBox) {
          if (!inDom) {
            const bodyEl = promptBox.querySelector('.prompt-box-8w p');
            const buttonCancel = promptBox.querySelector('.prompt-button-8w');
            const buttonPrimary = promptBox.querySelector('.button-primary-8w');
            const h2 = document.createElement('h2');

            h2.textContent = localize('safari_gyro_titel');
            promptBox.insertBefore(h2, bodyEl);

            promptBox.querySelector('.prompt-button-container-8w').classList.add('button-container');
            promptBox.classList.add('dialog');
            promptBox.classList.add('dialog--safari-motion-request');
            bodyEl.style.margin = '0';
            buttonCancel.classList.value = '';
            buttonPrimary.classList.value = 'primary';

            changeText(bodyEl, 'safari_gyro_body');
            changeText(buttonCancel, 'button_cancel');
            changeText(buttonPrimary, 'button_approve');
          }

          inDom = true;
        } else if (inDom) {
          inDom = false;
          observer.disconnect();
        }
      });
  
      observer.observe(document.body, {childList: true});

      // No need to translate English
      if (getCurrentLanguage() == 'en') {
        return
      }

      // Translating page that pops up if the user denies access to the camera on Android
      if (cameraDeniedAndroid) {
        changeText(cameraDeniedAndroid.querySelector('.loading-error-header'), 'camera_denied_android_header');
        changeText(cameraDeniedAndroid.querySelector('li:nth-child(1)'), 'camera_denied_android_general_step1');
        changeText(cameraDeniedAndroid.querySelector('li:nth-child(2)'), 'camera_denied_android_general_step2');

        changeText(cameraDeniedAndroid.querySelector('li.chrome-instruction:nth-child(3)'), 'camera_denied_android_step3');
        changeText(cameraDeniedAndroid.querySelector('li.chrome-instruction:nth-child(4)'), 'camera_denied_android_step4');
        changeText(cameraDeniedAndroid.querySelector('li.chrome-instruction:nth-child(5)'), 'camera_denied_android_step5');
        changeText(cameraDeniedAndroid.querySelector('li.chrome-instruction:nth-child(6)'), 'camera_denied_android_step6');

        changeText(cameraDeniedAndroid.querySelector('li.samsung-instruction:nth-child(7)'), 'camera_denied_samsung_step3');
        changeText(cameraDeniedAndroid.querySelector('li.samsung-instruction:nth-child(8)'), 'camera_denied_samsung_step4');
        changeText(cameraDeniedAndroid.querySelector('li.samsung-instruction:nth-child(9)'), 'camera_denied_samsung_step5');
        changeText(cameraDeniedAndroid.querySelector('li.samsung-instruction:nth-child(10)'), 'camera_denied_samsung_step6');

        changeText(cameraDeniedAndroid.querySelector('.loading-error-footer'), 'camera_denied_android_reload');
      }

      // Translating page that pops up if the user denies access to the camera on Safari
      if (cameraDeniedSafari) {
        changeText(cameraDeniedAndroid.querySelector('#cameraPermissionsErrorAppleMessage'), 'camera_denied_safari_reload');
        changeHTML(cameraDeniedAndroid.querySelector('.bottom-message'), localize('camera_denied_safari_bottom') + '<span class="wk-app-name"></span>');
      }

      // Translating when user denied the first motion sensor access question on Safari
      if (motionSensorDenied) {
        changeText(cameraDeniedAndroid.querySelector('h1'), 'motion_denied_header');
        changeText(cameraDeniedAndroid.querySelector('p'), 'motion_denied_body');
        changeText(cameraDeniedAndroid.querySelector('button'), 'button_refresh');

        // Translating when user denied the second motion sensor access question on Safari
        if (motionSensorHardDenied) {
          changeText(cameraDeniedAndroid.querySelector('h1'), 'motion_denied_header');
          changeText(cameraDeniedAndroid.querySelector('p:first-of-type'), localize('motion_denied_body') + '<span class="wk-app-name"></span>');
          changeText(cameraDeniedAndroid.querySelector('p:last-of-type'), 'motion_hard_deny_body');
        }
      }
    }

    window.XRExtras ? load() : window.addEventListener('xrextrasloaded', load)
  },
}

export {loadingLocalization}
