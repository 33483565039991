/**
 * Adds more functionality when creating a custom element
 * Import this file and extend it's class:
 *    export class EcYourCustomElement extends EcCustomElement
 * 
 * Add a html() method that returns a string with your html code.
 */

export default class EcCustomElement extends HTMLElement {
  get is() {
    return this.tagName.toLowerCase()
  }

  constructor() {
    super();

    if (typeof this.html == 'function') {
      this.innerHTML = this.html();
    } else {
      console.log(this.is, "You need to add a html() method that returns html as a string")
    }
  }

  emit(eventType, detail, bubbles = true) {
    this.dispatchEvent(new CustomEvent(eventType, {
      detail: detail,
      bubbles: bubbles
    }));
  }

  // Native lifecycles for custom elements
  connectedCallback() { }
  disconnectedCallback() { }
  attributeChangedCallback(name, oldValue, newValue) { }
}
