/*
* A container for image targets. Child entities are appended in dynamic-targeting.js
*/

const ecNamedImageTarget = {
  schema: {
    name: {type: 'string'},
    type: {type: 'string'},     // For debugging
    isActive: {type: 'bool'},   // Changed by child entity and used in 'dynamic-targeting'
    hasPlayed: {type: 'bool'},  // Used by the game
  },
  get is() {
    return 'ec-named-image-target'
  },
  init() {
    const {object3D} = this.el;
    
    const onready = () => {
      this.el.sceneEl.removeEventListener('realityready', onready);
      object3D.visible = false;
    }

    this.el.sceneEl.addEventListener('realityready', onready);

    const updatePosition = ({detail}) => {
      if (this.data.name === detail.name) {
        object3D.position.copy(detail.position);
        object3D.quaternion.copy(detail.rotation);
        object3D.scale.set(detail.scale, detail.scale, detail.scale);
        object3D.visible = true;

        this.el.firstElementChild.classList.add('cantap');
      }
    }
    
    const hideComponent = ({detail}) => {
      if (this.data.name === detail.name) {
        object3D.visible = false;

        // this.el.firstElementChild.object3D.visible = false;
        this.el.firstElementChild.classList.remove('cantap');
      }
    }

    const removeComponent = ({detail}) => {
      if (this.data.name === detail.name) {
        object3D.visible = false;
        
        this.data.isActive = false;

        if (this.el.firstElementChild) {
          this.el.firstElementChild.removeAttribute('ec-play-video'); // frees up resources, and removes click listener
          this.el.firstElementChild.removeAttribute('ec-image-fade'); // stops animation
          this.el.firstElementChild.object3D.clear()                  // removes components, like 'material' and 'geometry'
          this.el.firstElementChild.removeFromParent();               // sending event that detaches child from the DOM
          this.el.removeChild(this.el.firstElementChild);             // actually removing the child from the DOM
        }
        
        this.data.name = null;

        this.el.sceneEl.components.pool__imagetarget.returnEntity(this.el);

         // For the game
        if (this.data.hasPlayed) {
          this.el.emit(detail.name);
          this.data.hasPlayed = false;
        }
      }
    }

    this.el.sceneEl.addEventListener('xrimageupdated', updatePosition);
    this.el.sceneEl.addEventListener('xrimagelost', hideComponent);
    window.addEventListener('ec-remove-image-target', removeComponent);
  },
}

export {ecNamedImageTarget}
