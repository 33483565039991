/** 
* 8th Wall crops the image target, so this scales the image up to it's real size
* 'minDimension' is the smallest of either height or width, basically saying if it's a landscape or portrait image
* @param {float} scaledWidth  in meter
* @param {float} scaledHeight in meter
*/
const setScale = (el, width, height, minDimension) => {
  _setScale(el, _calculateDimensions(width, height, minDimension));
}

const _setScale = (el, {scaledWidth, scaledHeight}) => {
  el.object3D.scale.set(scaledWidth, scaledHeight)
}

const _calculateDimensions = (width, height, minDimension) => {
  return {
    scaledWidth:  minDimension * (width > height ? width / height : 1),
    scaledHeight: minDimension * (height > width ? height / width : 1)
  }
}

export { setScale }
