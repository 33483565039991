/**
 * 
 * @param {string} pointType from manifest file
 * @returns {string} event name
 */
const eventTypes = (pointType) => {
  switch (pointType) {
    case '360':
      return 'ec-show-360'
    case '3d':
      return 'ec-show-3d-model'
    case 'audio':
      return 'ec-play-audio'
    case 'dialog':
      return 'ec-dialog'
    case 'gallery':
      return 'ec-show-gallery'
    case 'game':
      return 'ec-start-game'
    case 'video':
      return 'ec-camera-feed-delegator'  // only used for closing
    case 'image':
      return null
    default:
      console.error(`Couln't find a correct type of id "${pointType}" in manifest`);
      break;
  }
};

export {eventTypes};
