/**
 * The last page that pops up when the user has entered the correct password
 */
import EcCustomElement from "../../ec-custom-element";
import { localize, localizeElements } from "../../../utils/language-helper";
import { analyticsSendTrigger } from "../../../utils/matomo-analytics";

export default class EcGame1WinnerPage extends EcCustomElement {
  __listener

  html() {
    return `
      <section>
        <h1 id="game1-winner-page-title">[title]</h1>
        <div id="game1-winner-page-body-first" class="full-page__body">[body]</div>
        <div id="game1-winner-page-body-second" class="full-page__body">[body]</div>
        <img class="full-page__fancy-border" src="" />
      </section>
    `
  }

  constructor() {
    super();

    this.__listener = this.showPage.bind(this);
    window.addEventListener(this.is, this.__listener);
  }

  connectedCallback() {
    localizeElements(this, ['game1-winner-page-title', 'game1-winner-page-body-second']);

    this.querySelector('img').src = process.env.SCALEWAY_ASSETS_URL + 'img/game/game1-winner-page-glasses.jpg';
  }

  disconnectedCallback() {
    window.removeEventListener(this.is, this.__listener);
  }

  showPage({detail}) {
    this.classList.remove('hide-animation--hidden');

    this.showTime(detail.gameName);
  }

  showTime(gameName) {
    let text = localize('game1_winner_page_body_first');

    let timeInMillis = localStorage.getItem('game1_nar_blev_helsingborg_en_stad_time');
    const MINUTES = Math.floor(timeInMillis / 60 / 1000);
    timeInMillis = timeInMillis - MINUTES * 60 * 1000;
    const SECONDS = Math.floor(timeInMillis / 1000);

    this.querySelector('#game1-winner-page-body-first').textContent = `${text} ${localize('game1_only_took')} ${MINUTES} ${localize('min')} ${SECONDS} ${localize('sec')}.`

    analyticsSendTrigger('game-finished', gameName, MINUTES + Math.round(SECONDS / 60)); // whole minutes
  }
}

customElements.define('ec-game1-winner-page', EcGame1WinnerPage);
