/**
 * Takes in scan information
 * Adds a search parameter to the url, so reloading the app takes it to the last activated view.
 * Update image targets via DynamicTargeting if search params has changed in the URL
 * Sends out 'ec-[show/play]-[type]' event with {action: "start"}, point{}, to activate other elements
 * Sends out 'ec-[show/play]-[type]' event with {action: "close"} as detail to the previously activated element
 * Entrance (no search params) returns image targets for room 1.
 */

import ManifestDownloader from './manifest-downloader';
import DynamicTargeting from './dynamic-targeting';
import { getSearchParams, updateSearchParams } from '../../utils/search-params';
import { analyticsSendPageView, analyticsSendTrigger } from '../../utils/matomo-analytics';
import { eventTypes } from '../../utils/event-types';

const cameraFeedDelegator = {
  get is() {
    return 'camera-feed-delegator'
  },
  
  init() {
    let currentRoomId = -1;
    let previousPoint = {};
    let isolatedImageTarget; // if a game is active and only one image target is valid

    const manifest = new ManifestDownloader();
    const dynamicTargeting = new DynamicTargeting();

    // Downloads all manifests named in manifest.roomsToPreload[]
    setTimeout(() => manifest.getAll(), manifest.RETRY_TIME);

    const isEntrance = (pointId) => { return pointId === null }

    /**
     * Runs when the XR has loaded
     */
    const firstParamsUpdate = () => {
      updateSearchParams(getSearchParams());
      notifyElements(getSearchParams());
      this.el.sceneEl.removeEventListener('realityready', firstParamsUpdate);
    }

    /**
     * Sends events based on the object eventTypes:
     * Gets the arguments from qr-pipeline
     * @param {int} roomId
     * @param {int/null} pointId 
     */
    const notifyElements = ({roomId, pointId}) => {
      manifest.get(roomId, (room) => {
        if (!room.id) { return }
         
        const point = room.points[pointId];
  
        if (currentRoomId !== roomId) {
          console.log(this.is, room.imageTargets);

          analyticsSendPageView(room.name, roomId, currentRoomId);
          dynamicTargeting.updateTargetElements(room.imageTargets);

          displayRoomNameBanner(room.name, point === undefined);

          currentRoomId = roomId;
        }
  
        if (!point) {
          if (!isEntrance(pointId)) {
            console.error(this.is, `Point ${pointId} in room ${roomId} doesn't exist in the manifest`);
          }
  
          return
        }

        sendEvents(point);
      });
    }

    const displayRoomNameBanner = (roomName, newScannedRoom) => {
      if (newScannedRoom) {
        this.el.emit('ec-notification-banner', {
            action: 'show',
            title: roomName,
            body: 'discover_cta_body',
            animation: currentRoomId != -1
          }
        );
      }
    }

    /**
     * Sends out three events: 'ec-notification-banner' and two with 'ec-EVENTTYPE'
     *  first one closes the notification banner
     *  the second one closes the previous opened event type with {action: 'close'}
     *  the last event starts a new one with {action: 'start'}
     * @param {object} point Information from the manifest file
     */
    const sendEvents = (point) => {
      this.el.emit('ec-notification-banner', {action: 'hide'});

      if (point.type != 'video') { analyticsSendTrigger(point.type, currentRoomId, point.name); }

      if (eventTypes(point.type)) {
        // Only send event if game (isolatedImageTarget) isn't active, and type has changed
        if (!isolatedImageTarget && previousPoint.type && previousPoint.type != point.type) {
          _sendEvent(eventTypes(previousPoint.type), {...previousPoint, ...{action: 'close'}});
        }

        previousPoint = point;
    
        _sendEvent(eventTypes(point.type), {...point, ...{action: 'start'}});
      }
    }

    const _sendEvent = (eventType, detail) => {
      console.log(this.is, `Sending event '${eventType}'`, detail);
      this.el.emit(eventType, detail, true);
    }
    /**
     * Adds elements to DOM and then sends an event with information to them
     * 'isolatedImageTarget' and 'point.origin' are relevant if the room has a game in it
     * @param {event} detail 
     */
    const addElementChildToImageTargetContainer = ({detail}) => {
      manifest.get(currentRoomId, (room) => {
        const point = room.points[detail.name];

        // The game isolates image targets so they are the only ones being able to be triggered
        if (isolatedImageTarget && isolatedImageTarget != detail.name) {
          this.el.emit('ec-dialog', {
            body: 'game1_wrong_target',
            primaryButton: {
              text: 'button_continue'
            },
          });
          
          console.log('Wrong target. Try', isolatedImageTarget);
        } else if (point.isolated_image_target == isolatedImageTarget) {  // common case outside the game: undefined == undefined
          dynamicTargeting.foundTarget(detail.name, detail, this.el.sceneEl, point);

          sendEvents(point);
        }
      })
    }

    this.el.sceneEl.addEventListener('realityready', firstParamsUpdate);

    window.addEventListener('ec-searchparams-change', ({detail}) => notifyElements(detail));

    window.addEventListener('xrimagefound', addElementChildToImageTargetContainer);
  
    window.addEventListener('xrimagelost', ({detail}) => {
      dynamicTargeting.lostTarget(detail.name, this.el.sceneEl);
    });

    /**
     * Communicates with camera-feed-delegator directly
     * 'close' is handled through close buttons to remove elements from DOM
     * 'isolate-target' restricts which image targets that can be triggered within a room
     * @param {event} detail 
     */
    window.addEventListener('ec-camera-feed-delegator', ({detail}) => {
      if (detail.action == 'close') {
        dynamicTargeting.removeTarget(detail.name);
      } else if (detail.action == 'isolate-origin') {
        isolatedImageTarget = detail.name;
      } else if (detail.action == 'show-room-information') {
        manifest.get(currentRoomId, (currentRoom) => {
          displayRoomNameBanner(currentRoom.name, true);
        });
      }
  });

    window.addEventListener('ec-image-targets-restart', () => {
      manifest.get(currentRoomId, (currentRoom) => {
        dynamicTargeting.updateTargetElements(currentRoom.imageTargets);
      })
    });
  }
}

export {cameraFeedDelegator}
