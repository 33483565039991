/**
 * Tracks room navigation. Used in 'camera-feed-delegator'
 * @param {string} title room name
 * @param {string} newRoomId 
 * @param {string} oldRoomId 
 */
const analyticsSendPageView = (title, newRoomId, oldRoomId) => {
  _paq.push(['setCustomUrl', '/?' + newRoomId]);

  if (oldRoomId !== -1) {
    _paq.push(['setReferrerUrl', '/?' + oldRoomId]);
  }
  
  _paq.push(['setDocumentTitle', title]);

  // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
  _paq.push(['deleteCustomVariables', 'page']);
  _paq.push(['trackPageView']);
}

/**
 * Sends in events.
 * @param {string} type type of event
 * @param {string} roomId usually roomId
 * @param {any} name can be whatever, numbers, point name etc.
 */
const analyticsSendTrigger = (type, roomId, name) => {
  _paq.push(['trackEvent', type, roomId, name]);
}

export { analyticsSendPageView, analyticsSendTrigger }
