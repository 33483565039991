/**
 * Checks every 30 seconds if the hash has changed from a deployment.
 * Displays a banner if this has happened
 */
const updateTracker = {
  init() {
    setInterval(() => this.checkUpdates(), 30000);
  },

  async checkUpdates() {
    try {
      let release = await fetch(`/release.json`);
      release = await release.json();
  
      if (this.releaseHash && release.hash != this.releaseHash) {
        window.dispatchEvent(new CustomEvent('ec-notification-banner', {detail: {
            action: 'show',
            title: 'reload_title',
            body: 'reload_body',
            clickAction: () => { location.reload() },
          }
        }));
      }
  
      this.releaseHash = release.hash;
    } catch (error) {
      // console.log(this.is, error);
    }
  }
}

export {updateTracker}
